import { convertToTwoDig } from "./stringFormat"
import moment from "moment-timezone"

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

/* eslint-disable */
export const DAYS_L = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
export const DAYS_S = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
export const DAYS_XS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
export const MONTHS_L = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
export const MONTHS_S = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

export const formatDate = (value, formatting = { month: "short", day: "numeric", year: "numeric" }) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

export const getAmPmTime = (hours, min) => {
  let h, amPm

  if (hours === 0) {
    h = 12
    amPm = "am"
  } else if (hours < 12) {
    h = hours
    amPm = "AM"
  } else if (hours === 12) {
    h = hours
    amPm = "PM"
  } else {
    h = hours - 12
    amPm = "PM"
  }
  return `${convertToTwoDig(h)}:${convertToTwoDig(min)} ${amPm}`
}

export const formatDateForOrdersPage = (date) => {
  const d = new Date(date)
  // d.setHours(d.getHours() - 12) // to do remove this or fix using moment.js
  // console.log(moment(d).tz("America/New_York").format("MM/DD/YY hh:mm a"))
  return `${DAYS_S[d.getDay()]}, ${MONTHS_S[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} ${getAmPmTime(
    d.getHours(),
    d.getMinutes()
  )}`
}

export const getTimeFromDate = (d) => {
  const date = new Date(d)
  return getAmPmTime(date.getHours(), date.getMinutes())
}
export const setEndTimeInDate = (d) => {
  const d2 = new Date(d)
  d2.setHours(23)
  d2.setMinutes(59)
  d2.setSeconds(59)
  d2.setMilliseconds(999)
  return d2.getTime()
}

export const setZeroTimeInDate = (d) => {
  const d1 = new Date(d)
  d1.setHours(0)
  d1.setMinutes(0)
  d1.setSeconds(0)
  d1.setMilliseconds(0)
  return d1.getTime()
}
export const defaultDateRange = [
  setZeroTimeInDate(new Date()) - 86400000,
  setZeroTimeInDate(new Date()) - 86400000,
]

export const addMonths = (date, months) => {
  const d = date.getDate()
  date.setMonth(date.getMonth() + +months)
  if (date.getDate() !== d) {
    date.setDate(0)
  }
  return date
}

export const formatDateToYYYMMDD = (date, seperator = "-") => {
  const d = new Date(date)
  return `${d.getFullYear()}${seperator}${convertToTwoDig(d.getMonth() + 1)}${seperator}${convertToTwoDig(
    d.getDate()
  )}`
}
export const dateFormatDDMMMYYYY = (d) => {
  const dateObject = new Date(d)
  return `${convertToTwoDig(dateObject.getDate())} ${
    MONTHS_S[dateObject.getMonth()]
  }, ${dateObject.getFullYear()}`
}

// returns day of year 1,2,3...365
export const getDayOfyear = (d) => {
  const dateObj = new Date(d)
  const start = new Date(dateObj.getFullYear(), 0, 0)
  const diff = dateObj - start + (start.getTimezoneOffset() - dateObj.getTimezoneOffset()) * 60 * 1000
  const oneDay = 1000 * 60 * 60 * 24
  const day = Math.floor(diff / oneDay)
  // console.log("Day of year: " + day)
  return day
}
// returns week of year 1,2,3...52
export const getWeekOfYear = (d) => {
  const dateObj = new Date(d)
  const dayNum = dateObj.getUTCDay() || 7
  dateObj.setUTCDate(dateObj.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(dateObj.getUTCFullYear(), 0, 1))
  const week = Math.ceil(((dateObj - yearStart) / 86400000 + 1) / 7)
  // console.log("week: ", week)
  return week
}

//input epoch timestamps in ms
export const getDateDifferenceAsString = (msDiff) => {
  const ONE_MIN = 60 * 1000
  const ONE_HOUR = ONE_MIN * 60
  const ONE_DAY = ONE_HOUR * 24
  const ONE_WEEK = ONE_DAY * 7
  const ONE_MONTH = ONE_DAY * 30
  const ONE_YEAR = ONE_DAY * 365

  if (msDiff < ONE_MIN) return `${Math.round(msDiff / 1000)} seconds`
  if (msDiff < ONE_HOUR) {
    const first = Math.round(msDiff / ONE_MIN)
    const sec = Math.round((msDiff % ONE_MIN) / 1000)
    let str = `${first} minute${first > 1 ? "s" : ""}`
    if (sec > 0) str += ` & ${sec} sec${sec > 1 ? "s" : ""}`
    return str
  }
  if (msDiff < ONE_DAY) {
    const first = Math.round(msDiff / ONE_HOUR)
    const sec = Math.round((msDiff % ONE_HOUR) / ONE_MIN)
    let str = `${first} hour${first > 1 ? "s" : ""}`
    if (sec > 0) str += ` & ${sec} min${sec > 1 ? "s" : ""}`
    return str
  }
  if (msDiff < ONE_WEEK) {
    const first = Math.round(msDiff / ONE_DAY)
    const sec = Math.round((msDiff % ONE_DAY) / ONE_HOUR)
    let str = `${first} day${first > 1 ? "s" : ""}`
    if (sec > 0) str += ` & ${sec} hour${sec > 1 ? "s" : ""}`
    return str
  }
  if (msDiff < ONE_MONTH) {
    const first = Math.round(msDiff / ONE_WEEK)
    const sec = Math.round((msDiff % ONE_WEEK) / ONE_DAY)
    let str = `${first} week${first > 1 ? "s" : ""}`
    if (sec > 0) str += ` & ${sec} day${sec > 1 ? "s" : ""}`
    return str
  }
  if (msDiff < ONE_YEAR) {
    const first = Math.round(msDiff / ONE_MONTH)
    const sec = Math.round((msDiff % ONE_MONTH) / ONE_WEEK)
    let str = `${first} month${first > 1 ? "s" : ""}`
    if (sec > 0) str += ` & ${sec} week${sec > 1 ? "s" : ""}`
    return str
  }
  const first = Math.round(msDiff / ONE_YEAR)
  const sec = Math.round((msDiff % ONE_YEAR) / ONE_MONTH)
  let str = `${first} year${first > 1 ? "s" : ""}`
  if (sec > 0) str += ` & ${sec} month${sec > 1 ? "s" : ""}`
  return str
}

// returns epoch ms
export const getStartAndEndDateFromPreset = (preset) => {
  const mapObj = {
    today: [moment().startOf("day"), moment()],
    yesterday: [moment().subtract(1, "days").startOf("day"), moment().subtract(1, "days").endOf("day")],
    last7Days: [moment().subtract(6, "days").startOf("day"), moment()],
    thisWeek: [moment().startOf("isoweek"), moment()],
    lastWeek: [
      moment().subtract(1, "isoweek").startOf("isoweek"),
      moment().subtract(1, "isoweek").endOf("isoweek"),
    ],
    last30Days: [moment().subtract(29, "days").startOf("day"), moment()],
    thisMonth: [moment().startOf("month").startOf("day"), moment()],
    lastMonth: [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
    thisYear: [moment().startOf("year"), moment()],
    lastYear: [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
  }

  const end = mapObj[preset][1].valueOf() > Date.now() ? Date.now() : mapObj[preset][1].valueOf()
  return { start: mapObj[preset][0].valueOf(), end } // start and and of that day
}

// data
export const presetDateOptions = [
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 Days", value: "last7Days" },
  { label: "This Week", value: "thisWeek" },
  { label: "Last Week", value: "lastWeek" },
  { label: "Last 30 Days", value: "last30Days" },
  { label: "This Month", value: "thisMonth" },
  { label: "Last Month", value: "lastMonth" },
  { label: "This Year", value: "thisYear" },
  { label: "Last Year", value: "lastYear" },
]

export const daysBetweenEpochDates = (epochDate1, epochDate2) => {
  const millisecondsPerDay = 86400000;

  const differenceInMilliseconds = epochDate1 - epochDate2;

  const differenceInDays = differenceInMilliseconds / millisecondsPerDay;
  return Math.round(differenceInDays);
};

export const subtractDaysFromEpoch = (epochDate, days) => {
  const millisecondsPerDay = 86400000
  const daysInMilliseconds = days * millisecondsPerDay

  const newEpochDate = epochDate - daysInMilliseconds

  return newEpochDate
}
